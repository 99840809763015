<!-- Mto Grupos funerarios -->

<template>
  <div class="gruposM" v-if="loaded">    
    <dualTemplate
      :dualTipo="sync? '':'modal'"
      :modal="modal"
      :storeName="storeRaiz"
       persistent>  

       <template v-slot:controles="{}">

          <div>
            
            <!-- Cabecera -->
            <div class="cabecera">     
              <baseHeader
                :cfg="$cfe.headers.mto"
                :titulo='$store.state[storeName].titulo'                
                @onEvent="eventHeader">
              </baseHeader>
            </div>

            <v-sheet :elevation="4"> 
              <div class="contenedor" style="width:500px">     
                <!-- <div v-html="info"></div> -->

                <!-- Botones Mto -->
                <div v-if="!readOnly" class="pt-2 d-flex justify-left">

                  <!-- Mto -->            
                  <baseBtraMto
                    :perm="permMto"
                    :modulo="btMtoCfg" 
                    :estado="estado"
                    @onEvent="execAccion">        
                  </baseBtraMto>

                  <!-- Extra -->
                  <baseBtraExtra
                    style="margin-left:25px"
                    :permExtra="permExtra"
                    :modulo="btExtCfg"           
                    @onEvent="execAccion">             
                  </baseBtraExtra>  

                </div>      

                <!-- Controles del Mto -->
                <div class="columna" style="padding-top:20px">    
                  <uiText
                    style="font-weight: bold;"       
                    v-model=" ct.name[2]"
                    :label=" ct.name[1]"
                    :disabled="noEdit">
                  </uiText>

                  <!-- <ctrlF               
                    :ct="exp"
                    :edicion="!noEdit">                        
                  </ctrlF> -->
                </div>
              </div>
            </v-sheet>


            <!-- Ventana de Componentes Extra  -->      
            <component      
              :is="componenteDinamico"
              syncUpdate
              :storeRaiz="storeName"
              :masterStore="storeName"
              :readOnly="readOnly"
              :viewMtoOnly="viewMtoOnly"
              tipoDocumento='6'
              @onEvent="$event.accion==6 || $event.accion=='salir'? componenteDinamico=null : ''">
            </component>
          </div>
       </template>
    </dualTemplate>
  </div>
</template>



<script>

  import { mixM } from "@/mixins/mixM.js"; 
  import baseHeader from "@/base/baseHeader";
  import dualTemplate from "@/components/dualTemplate";
  import baseBtraMto from "@/base/baseBtraMto";
  import baseBtraExtra from "@/base/baseBtraExtra"; 
  import commonDocsF from "@/components/commonDocsF";  
     
  export default {
    mixins: [mixM],  
    components: { baseHeader, dualTemplate, baseBtraMto, baseBtraExtra, commonDocsF },
    props: {},

    data() {
      return {
        stIni: {
          api: "gruposMX",
          name:"gruposMX",          
          titulo:"Grupos Funerarias",
          recordAccess:"local",
          mView:'',
          pView:[]        
        },  

        /* exp:['loc', 'Origen',  '', {},
            {comp: {
                type:"pickV1",
                api:"generic",						
                source:"exp",
                fAux:"name",					
                valueAux:"",                            
                finder:"expF",
                tipoView:"FC",
                mView:"", 
                ctView:""
            }}
        ], */
      };
    },  
    

    methods: {
      iniDataParticular() {
        console.log("DEV ********************** (iniDataParticular)"); 

        // añado botones extra
        this.btExtCfg.btnsAccion= [
          { accion:'docs', btn: "docs" }        
        ];
      },

     
      // gancho final para todas las operaciones de CRUD
      crudFin() {
        this.botonerasSet();
      },


      // COMPONENTES DINAMICOS (asignación)
      //
      docs() {
        this.componenteDinamico= 'commonDocsF';
      },


      //
      botonerasSet() {
        this.btnSet(this.btExtCfg, 'docs', { view: true, disabled:!this.noEdit? true : false });        
      },

    },


};
</script>
